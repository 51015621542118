import React from 'react';

export class AsyncCss extends React.Component {
  componentDidMount () {
    if (!this.props.url) return;
    var linkElement = document.createElement('link');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
    linkElement.setAttribute('href', this.props.url);
    document.head.appendChild(linkElement);
  }

  render = () => <React.Fragment />;
}