import React from 'react';
import classNames from 'classnames';
import { ChatManager } from '../../classes/chat.manager';
import { UserManager } from '../../classes/user.manager';

export class Input extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      connected: false,
      nickname: '',
      uploading: false,
      loggedIn: false
    };
  }

  get className () {
    return classNames({
      'input': true,
      'input--uploading': this.state.uploading,
      'input--logged-in': this.state.loggedIn
    });
  }

  auth = (e) => {
    e.preventDefault();
    window.wordy.auth.show();
  }

  logout = (e) => {
    e.preventDefault();
    UserManager.instance.logout();
  }

  chat = (e) => {
    e.preventDefault();
    ChatManager.instance.chat(this.refs.input.value);
    this.refs.input.value = '';
  }

  componentDidMount () {
    ChatManager.instance.on('nickname-changed', this.nicknameUpdated);
    UserManager.instance.on('login', () => this.setState({ loggedIn: true }));
    UserManager.instance.on('logout', () => {
      console.log('Logged out event');
      this.setState({ loggedIn: false });
    });
  }

  nicknameUpdated = () => {
    this.setState({
      connected: true,
      nickname: ChatManager.instance.nickname,
      avatar: ChatManager.instance.avatar
    });
  }

  selectPhoto = () => {
    this.state.loggedIn && this.refs.file.click();
  }

  onFilesSelected = (e) => {
    if (e.target.files.length < 1) { return; }
    const file = e.target.files[0];
    
    if (file.size > 8*1000*1000) { alert('Sorry, max 8mb files'); return;}

    this.setState({ file, uploading: true });
    var reader = new FileReader();
    reader.onload = this.onFileRead;
    reader.readAsDataURL(file);
  }
  
  onFileRead = (e) => {
    const match = /^data:(.*);base64,(.*)$/.exec(e.target.result);
    const data = {
      'mime': match[1],
      'content': match[2],
      'name': this.state.file.name
    }

    UserManager.instance.photo(data).then(({ photoUri }) => {
      this.setState({ avatar: photoUri, uploading: false });
    }).catch(e => {
      this.setState({ uploading: false });
    });
  }

  render = () => (
    <div className={this.className}>
      <div className="input__left" onClick={this.selectPhoto}>
        { this.state.avatar && <img src={ this.state.avatar } className="input__avatar" /> }
        <input type="file" id="file-input" ref="file" accept="image/*" onChange={this.onFilesSelected} />
        <div className="input__left__loading">
          <img src="/rings.svg" />
        </div>
      </div>
      <div className="input__right">
        <form onSubmit={this.chat}>
          <input type="text" className="input__textfield" placeholder="Type here..." ref="input" />
          <input type="submit" className="input__submit" value="&#xf1d8;" />
        </form>
        
        <div className="input__options">
          <div className="input__options__left">
            { 
              this.state.connected
              ? this.renderConnected()
              : this.renderConnecting()
            }
          </div>
          <div className="input__options__right"></div>
        </div>
      </div>
    </div>
  )

  renderConnecting = () => (
    <>
      Connecting...
    </>
  )

  renderConnected = () => (
    <>
      Your nickname: { this.state.nickname }&nbsp;
      <a href="nickname" onClick={this.auth} className="auth__change">(change)</a>
      <a href="logout" onClick={this.logout} className="auth__logout">(logout)</a>
    </>
  )
}