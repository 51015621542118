import React from 'react';

import { ChatManager } from './classes/chat.manager';
import { Bubble } from './components/bubble';
import { Input } from './components/input';
import classNames from 'classnames';

import './base/wordy.scss';
import { AsyncCss } from './components/async-css';
import { Auth } from './components/auth';
import { UserManager } from './classes/user.manager';
import { ErrorScreen } from './components/error-screen';

export class Wordy extends React.Component {
  constructor () {
    super();
    this.state = {
      messages: [],
      loggedIn: false
    }
  }

  get className () {
    return classNames({
      'wordy': true,
      'wordy--logged-in': this.state.loggedIn
    });
  }

  componentDidMount () {
    ChatManager.instance.on('message', this.update);
    UserManager.instance.on('login', this.login);
    UserManager.instance.on('logout', this.logout);

    const init = () => {
      UserManager.instance.init().then(() => {
        ChatManager.instance.init();
        window.scrollTo(0, document.body.scrollHeight);
      });
    }
    
    setTimeout(init, 500);
  }

  componentWillUnmount () {
    ChatManager.instance.off('message', this.update);
  }

  update = () => {
    this.setState({ messages: ChatManager.instance.messages }, () => {
      window.scrollTo(0, document.body.scrollHeight);
    });
  }

  login = () => {
    this.setState({ loggedIn: true });
    ChatManager.instance.changeNickname();
  }

  logout = () => {
    this.setState({ loggedIn: false });
  }

  render = () => (
    <div className={this.className}>
      <AsyncCss url="https://fonts.googleapis.com/css2?family=Varela+Round&display=swap" />
      <AsyncCss url="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
      <div className="wordy__messages">{ this.state.messages.map(message => <Bubble key={message.id} {...message} />) }</div>
      <div className="wordy__input"><Input /></div>
      <Auth />
      <ErrorScreen ref="errorScreen" />
    </div>
  )
}
