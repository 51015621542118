import React from 'react';
import classNames from 'classnames';

export class ErrorScreen extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      visible: false,
      title: 'error',
      message: 'Vivamus rhoncus tortor quis metus interdum, eu pharetra urna vulputate. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.'
    };
  }

  componentDidMount () {
    if (!window.wordy) window.wordy = {};
    window.wordy.errorScreen = this;
  }

  show = ({ message }) => {
    this.setState({ visible: true, message });
  }

  hide = () => {
    this.setState({ visible: false });
  }

  get className () {
    return classNames({
      'error-screen': true
    }); 
  }

  render = () => {
    if (!this.state.visible) return <div />;
    return (
      <div className="error-screen__wrapper">
        <div className={this.className}>
          <div className="error-screen__body">{ this.state.message }</div>
          <button onClick={this.hide}>OK</button>
        </div>
      </div>
    )
  }
}