import React from 'react';
import classNames from 'classnames';

export class Bubble extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  get className () {
    return classNames({
      'bubble': true
    });
  }

  componentDidMount () {
    window.scrollTo(0, document.body.scrollHeight);
  }

  didLoad () {
    window.scrollTo(0, document.body.scrollHeight);
    setTimeout(() => { window.scrollTo(0, document.body.scrollHeight); }, 250);
    setTimeout(() => { window.scrollTo(0, document.body.scrollHeight); }, 500);
  }

  get avatar () {
    var url = this.props.avatar;
    if (url.indexOf('adorable') > -1)
      url = 'https://eenmk82feb.execute-api.eu-west-1.amazonaws.com/production/avatar/' + this.props.nickname;
    return url;
  }

  render = () => (
    <div className={this.className}>
      <div className="bubble__body">
        <div className="bubble__body__photo">
          <img src={ this.avatar } alt={ this.props.nickname } />
        </div>
        <div className="bubble__body__message">
          <div className="bubble__body__message__nickname">{ this.props.nickname }</div>
          { this.props.content && <div className="bubble__body__message__message">{ this.props.content }</div> }
          { this.props.image && <img className="bubble__body__message__image" src={ this.props.image } onLoad={this.didLoad} /> }
        </div>
      </div>
      <div className="bubble__meta">
        
      </div>
    </div>
  )
}