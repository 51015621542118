import axios from 'axios';
import Amplify, { Auth } from 'aws-amplify';
import EventDispatcher from 'eventdispatcher';

import { ChatManager } from './chat.manager';


var _instance = null;

Amplify.configure({
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_Z2JjSw740',
    userPoolWebClientId: '675lru6o0nn18r3c3aqijtl5g7',
    mandatorySignIn: true
  }
});

export class UserManager extends EventDispatcher {
  static get instance () {
    if (!_instance) _instance = new UserManager();
    return _instance;
  }

  init = () => new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then(user => {
      this.didLogin(user);
      resolve();
    }).catch(() => {
      resolve();
    });
  });

  get accessToken () {
    if (!this.currentUser) return false;
    return this.currentUser.signInUserSession.accessToken.jwtToken;
  }

  register ({ nickname, email, password }) {
    this.__password = password;
    const picture = 'https://api.adorable.io/avatars/100/' + nickname;
    return Auth.signUp({ username: nickname, password, attributes: { email, picture }});
  }

  login ({ nickname, password }) {
    return new Promise((resolve, reject) => {
      Auth.signIn(nickname, password).then(user => {
        this.didLogin(user);
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  forgotYourPassword ({ email }) {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(email).then(() => { 
        resolve();
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  forgotYourPasswordConfirm ({ email, code, password }) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(email, code, password).then(() => { 
        resolve();
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  logout () {
    sessionStorage.removeItem('wordy-key');
    Auth.signOut().then(() => {
      this.currentUser = null;
      this.emit('logout');
      ChatManager.instance.hello();
    })
  }

  confirm ({ nickname, code }) {
    return new Promise((resolve, reject) => {
      Auth.confirmSignUp(nickname, code).then(() => {
        this.login({ nickname, password: this.__password }).then(resolve).catch(reject);
      }).catch(reject);
    })
  }

  photo ({ name, mime, content }) {
    const uuid = ChatManager.instance.sessionKey;
    const endpoint = 'https://eenmk82feb.execute-api.eu-west-1.amazonaws.com/production/photo';
    const data = { name, mime, content, accessToken: this.accessToken, uuid };
    
    return new Promise((resolve, reject) => {
      axios.post(endpoint, data).then(response => {
        resolve(response.data);
      });
    });
  }

  didLogin = (user) => {
    this.currentUser = user;
    this.emit('login');
  }
}