import React from 'react';
import classNames from 'classnames';
import { UserManager } from '../../classes/user.manager';
import { ChatManager } from '../../classes/chat.manager';

export class Auth extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      visible: false,
      screen: 'login',
      nickname: '',
      password: '',
      email: '',
      code: ''
    };
  }

  componentDidMount () {
    if (!window.wordy) window.wordy = {};
    window.wordy.auth = this;

    UserManager.instance.on('login', this.hide);
  }

  show = () => {
    this.setState({ visible: true });
  }

  hide = () => {
    this.setState({ visible: false });
  }

  get className () {
    return classNames({
      'auth': true
    }); 
  }

  toRegistration = (e) => {
    e.preventDefault();
    this.setState({ screen: 'register' });
  }

  toForgotPassword = (e) => {
    e.preventDefault();
    this.setState({ screen: 'forgot' });
  }

  toForgotPasswordConfirm = (e) => {
    e.preventDefault();
    this.setState({ screen: 'forgot-confirm' });
  }

  change = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/\s/g,'')
    });
  }

  register = (e) => {
    e.stopPropagation();
    e.preventDefault();
    UserManager.instance.register(this.state).then(response => {
      this.setState({ screen: 'confirm' });
    }).catch(error => {
      this.setState({ error: error.message });
    });
  }

  confirm = (e) => {
    e.stopPropagation();
    e.preventDefault();

    UserManager.instance.confirm(this.state).then(response => {
      console.log(response);
    }).catch(error => {
      this.setState({ error: error.message });
    });
  }

  login = (e) => {
    e.stopPropagation();
    e.preventDefault();

    UserManager.instance.login(this.state).then(response => {
      console.log(response);
    }).catch(error => {
      this.setState({ error: error.message });
    });
  }

  changeNick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    ChatManager.instance.on('nickname-changed', this.nicknameChanged)
    ChatManager.instance.on('error', this.nicknameError)
    ChatManager.instance.changeNickname(this.state.nickname);
    this.setState({ nicknameError:  null })
  }

  nicknameError = (error) => {
    ChatManager.instance.off('nickname-changed', this.nicknameChanged)
    ChatManager.instance.off('error', this.nicknameError)
    this.setState({ nicknameError: error.message });
  }

  nicknameChanged = () => {
    ChatManager.instance.off('nickname-changed', this.nicknameChanged)
    ChatManager.instance.off('error', this.nicknameError)
    this.hide();
  }

  render = () => {
    if (!this.state.visible) return <div />;
    if (this.state.screen === 'login') return this.renderLogin();
    if (this.state.screen === 'register') return this.renderRegister();
    if (this.state.screen === 'confirm') return this.renderConfirm();
    if (this.state.screen === 'forgot') return this.renderForgotPassword();
    if (this.state.screen === 'forgot-confirm') return this.renderForgotPasswordConfirm();
  }

  renderLogin = () => (
    <div className="auth__wrapper">
      <div className={this.className}>
        <div className="auth__title">Set your nickname</div>
        <form onSubmit={this.changeNick}>
          <div className="auth__subtitle">Temporary</div>
          <p>Quick and easy, but we won't hold on to it.</p>
          <div className="auth__row">
            <input type="text" placeholder="Your nickname" name="nickname" value={this.state.nickname} onChange={this.change}  />
            <button onClick={this.changeNick}>update</button>
          </div>
          { this.state.nicknameError && <p class="error">{ this.state.nicknameError }</p> }
        </form>
        <form onSubmit={this.login}>
          <div className="auth__subtitle">Permanently</div>
          <p>Sign up and reserve your nickname. You'll also be able to customize your avatar and post gifs.</p>
          <input type="text" placeholder="Your nickname" name="nickname" value={this.state.nickname} onChange={this.change}  />
          <input type="password" placeholder="Your password" name="password" value={this.state.password} onChange={this.change}  />
          { this.state.error && <p class="error">{ this.state.error }</p> }
          <div className="auth__buttons">
            <button onClick={this.login}>login</button>
            <button onClick={this.toRegistration}>register</button>
            <div><a href="forgot" onClick={this.toForgotPassword}>Forgot your password?</a></div>
          </div>
        </form>
      </div>
    </div>
  )

  renderRegister = () => (
    <div className="auth__wrapper">
      <div className={this.className}>
        <div className="auth__title">Set your nickname</div>
        <form onSubmit={this.register}>
          <div className="auth__subtitle">Permanently</div>
          <p>Sign up and reserve your nickname.</p>
          <input type="text" placeholder="Your nickname" name="nickname" value={this.state.nickname} onChange={this.change}  />
          <input type="password" placeholder="Your password" name="password" value={this.state.password} onChange={this.change}  />
          <input type="text" placeholder="Your e-mail address" name="email" value={this.state.email} onChange={this.change} />
          <p>No worries, we use your e-mail address only in case you forgot your password.</p>
          { this.state.error && <p class="error">{ this.state.error }</p> }
          <div className="auth__buttons">
            <button onClick={this.register}>register</button>
          </div>
        </form>
      </div>
    </div>
  )

  renderConfirm = () => (
    <div className="auth__wrapper">
      <div className={this.className}>
        <div className="auth__title">Set your nickname</div>
        <form onSubmit={this.confirm}>
          <div className="auth__subtitle">Confirm</div>
          <p>We've sent a confirmation code to {this.state.email}. Please enter it here.</p>
          <input type="text" placeholder="Confirmation code" name="code" value={this.state.code} onChange={this.change}  />
          { this.state.error && <p class="error">{ this.state.error }</p> }
          <div className="auth__buttons">
            <button onClick={this.confirm}>confirm your account</button>
          </div>
        </form>
      </div>
    </div>
  )

  renderForgotPassword = () => (
    <div className="auth__wrapper">
      <div className={this.className}>
        <div className="auth__title">Set your nickname</div>
        <form>
          <div className="auth__subtitle">Forgot your password?</div>
          <p>Can happen to the best of us!<br/>Enter your e-mail address below.</p>
          <input type="text" placeholder="Your e-mail address" name="email" value={this.state.email} onChange={this.change} />
          <div className="auth__buttons">
            <button onClick={this.toForgotPasswordConfirm}>send me a reset code</button>
          </div>
        </form>
      </div>
    </div>
  )

  renderForgotPasswordConfirm = () => (
    <div className="auth__wrapper">
      <div className={this.className}>
        <div className="auth__title">Set your nickname</div>
        <form>
          <div className="auth__subtitle">Forgot your password?</div>
          <p>Great, if you are registered, you should have received a confirmation code to reset your password.</p>
          <input type="text" placeholder="Your e-mail address" name="email" value={this.state.email} onChange={this.change} />
          <div className="auth__buttons">
            <button>send me a reset code</button>
          </div>
        </form>
      </div>
    </div>
  )
}