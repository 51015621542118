import EventDispatcher from 'eventdispatcher';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { UserManager } from './user.manager';

const endpoint = 'wss://socket.wordy.chat';

var _instance = null;

export class ChatManager extends EventDispatcher {
  static get instance () {
    if (!_instance) _instance = new ChatManager();
    return _instance;
  }

  constructor () {
    super();
    this.messages = [];
  }

  init () {
    this.socket = new ReconnectingWebSocket(endpoint);
    this.socket.addEventListener('open', this.onConnection);
    this.socket.addEventListener('message', this.onMessage);
  }

  get sessionKey () {
    if (!sessionStorage.getItem('wordy-key')) this.sessionKey = UUID();
    if (sessionStorage.getItem('wordy-key') == null) this.sessionKey = UUID();
    if (sessionStorage.getItem('wordy-key') == 'null') this.sessionKey = UUID();
    return sessionStorage.getItem('wordy-key');
  }

  set sessionKey (value) {
    if (!value) sessionStorage.removeItem('wordy-key');
    sessionStorage.setItem('wordy-key', value);
  }

  onConnection = () => {
    this.emit('connected');
    this.hello();
  }

  send = (message) => {
    this.socket.send(JSON.stringify(message));
  }

  hello () {
    this.send({
      message: 'hello',
      uuid: this.sessionKey,
      room: window.wordy.room,
      accessToken: UserManager.instance.accessToken
    });   
  }

  chat = (message) => {
    this.send({
      message: 'chat',
      uuid: this.sessionKey,
      accessToken: UserManager.instance.accessToken,
      room: window.wordy.room,
      content: message
    })
  }

  onMessage = (message) => {
    message = JSON.parse(message.data);
    if (this[message.message]) this[message.message](message);
  }

  bonjour = ({ nickname, avatar }) => {
    this.nickname = nickname;
    this.avatar = avatar;
    this.emit('nickname-changed');
  }

  error = (error) => {
    console.log(error);
    this.emit('error', { message: error.description });
  }

  message = (message) => {
    this.messages.push(message);
    this.emit('message');
  }

  history = (message) => {
    this.messages = message.messages;
    this.emit('message');
  }

  changeNickname = (nickname = false) => {
    this.send({
      message: 'change',
      uuid: this.sessionKey,
      nickname: nickname,
      accessToken: UserManager.instance.accessToken
    });
  }
}

function UUID() {
  function s(n) { return h((Math.random() * (1<<(n<<2)))^Date.now()).slice(-n); }
  function h(n) { return (n|0).toString(16); }
  return  [
      s(4) + s(4), s(4),
      '4' + s(3),                    // UUID version 4
      h(8|(Math.random()*4)) + s(3), // {8|9|A|B}xxx
      // s(4) + s(4) + s(4),
      Date.now().toString(16).slice(-10) + s(2) // Use timestamp to avoid collisions
  ].join('-');
}